<template>
  <div>
    <div class="flix-panel" :class="[{'flix-panel-success': membership == 'premium'}, {'flix-panel-info': membership == 'free'}]">
      <div class="flix-panel-heading flix-text-center">
        <h2 class="flix-html-h2" style="margin-bottom: 0px"><flixIcon :id="'crown'" /> Premium</h2>
        {{ $t('message.membership') }}
      </div>
      <div class="flix-panel-body" style="padding: 0">
        <div class="flix-list-group" >
          <a href="https://web.bookingflix.com/prices" target="_blank" class="flix-list-group-item flix-html-a">
            <flixIcon class="icon flix-text-info" :id="'info-sign'"/>
            {{ $tc('message.function', 2) }} &amp; {{ $tc('message.price', 2) }}
            <div class="flix-clearfix" />
          </a>
          <div class="flix-list-group-item flix-active flix-text-center" v-if="membership === 'premium'">
            {{ $t('message.active') }}
          </div>
          <transition name="flixFadeIn">
            <div :key="open" v-if="membership !== 'premium'">
            <div class="flix-list-group-item flix-text-center" >
              <router-link :to="{name: 'dashboardPremium'}" class="flix-html-h2" style="margin: 0">Jetzt Premium-Mitgliedschaft starten</router-link>
            </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    membership: String
  },
  data () {
    return {
      open: false,
      includes: [
        'alle Funktionen von Terminflix nutzbar',
        'bis zu drei Terminformulare je Account/ Firma/ Unternehmung',
        'unbegrenzte Anzahl Terminbuchungen je Monat',
        'Termine, für Ihre Kunden, bis 12 Monate im Voraus buchbar',
        'Persönlicher Support per Telefon, Email und Support-Chat'
      ]
    }
  },
  methods: {

  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .icon
    font-size: 2em
    margin-right: 8px
    display: block
    float: left
    margin-bottom: 10px
</style>
